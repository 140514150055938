/* Menu */

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  background-color: #131313 !important; 
  color: #fff !important; 
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected:hover,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
  background-color: #131313 !important; 
  color: #fff !important;
}
.ant-menu-dark.ant-menu-submenu-popup>.ant-menu, .ant-menu-dark>.ant-menu.ant-menu-submenu-popup>.ant-menu {
  background-color: #131313;
}

.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #2c2c2c;
}


.ant-menu-submenu-popup .ant-menu-item-icon svg {
  margin-left: 38px;
  margin-top: 10px;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  width: 20px;
}
/* Estilo de Cartão */

.ant-card {
  color: rgba(255, 255, 255, 0.88); 
  background: #131313; 
  border-radius: 5px; 
  border: 1px solid #2c2c2c;
}

.ant-card .ant-card-head {
  color: #ffffff;
}

/* Caixa de Texto */

.ant-input {
  color: #ffffff;
}


.ant-input {
  background-color: #2c2c2c;
}

.ant-input::placeholder {
  color: #8a8a8a; 
}


.ant-input-affix-wrapper >input.ant-input, .ant-input-affix-wrapper >textarea.ant-input {
  background-color: #2c2c2c;
  color: #ffffff;
}

.ant-input-borderless {
  background-color: #2c2c2c;
}

/* Botão Primario */

.ant-btn-primary {
  background-color: #2c2c2c; 
  color: white;
  box-shadow: 0 0px 0 rgba(255, 255, 255, 0.1);
}

.ant-btn-primary:hover {
  background-color: #8a8a8a; 
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #2c2c2c; 
  color: #ffffff; 
}

/* Seletor de Data */

.ant-picker .ant-picker-input >input {
  background-color: #2c2c2c; 
  color: #ffffff;
}

.ant-picker-outlined{
  background-color: #2c2c2c; 
  color: #ffffff;
}

.ant-picker-outlined:hover {
  background-color: #2c2c2c; 
  color: #ffffff;
}

.ant-picker-outlined{
  border-color: #2c2c2c;
  background-color: #2c2c2c; 
  color: #ffffff;
}

.ant-picker-dropdown .ant-picker-panel-container {

  background-color: #2c2c2c; 
  color: #ffffff;
}

.ant-picker-dropdown .ant-picker-content th, .ant-picker-dropdown .ant-picker-content td {

  color: #ffffff
}

.ant-picker-dropdown .ant-picker-header button {
  color: #ffffff; 
}

.ant-picker-dropdown .ant-picker-header-view >button:hover {
  color: #8a8a8a;
}

a {
  color: #ffffff; 
}

a:hover {
  color: #ffffff;
  text-decoration: none; 
}


.ant-picker-outlined {
  border-color: #2c2c2c;
}

.ant-picker-outlined:focus, .ant-picker-outlined::focus-within {
  border-color: #2c2c2c;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  color: #8a8a8a
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
 border: 1px, solid, #ffffff;

}


.ant-picker .ant-picker-input > input::placeholder {
  color: #8a8a8a; 
}


.ant-picker:focus,
.ant-picker:hover {
    border-color: transparent; 
    box-shadow: none; 
}


.ant-picker.ant-picker-focused {
    border-color: transparent; 
    box-shadow: none;
}


.ant-picker .ant-picker-suffix {
  color: #ffffff; 
}


.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  color: #ffffff; 
  background: #8a8a8a;
}


.ant-picker.ant-picker-focused {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
}

.anticon >* {
  line-height: 1;
  color: white;
}

/* Cursor do Mouse */

.custom-cursor {
  cursor: url('https://i.imgur.com/9Lw9Rjf.png') 3 3, auto; 
  
}

/* Switch */
.ant-switch-checked {
  background-color: #ff0000 !important;
}

/* Table */

.ant-table-wrapper .ant-table {
  background-color: #2c2c2c;
  
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
  background-color: #2c2c2c;
  border-bottom: 1px solid #8a8a8a;
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover .ant-table-thead >tr>td {
  color: #ffffff;
}

.ant-table-wrapper .ant-table-tbody >tr >th, .ant-table-wrapper .ant-table-tbody >tr >td {
  color: #ffffff;
  border-bottom: 0px;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
  background-color: #2c2c2c;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row {
  background-color: #2c2c2c;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #8a8a8a;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
  background-color: #2c2c2c;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>th, .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>td, .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
  background-color: #2c2c2c;
}

.ant-empty-normal .ant-empty-description {
  color: #ffffff;
}

/* Select */


.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #ffffff;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #00000000;
  border: 0px, solid, #8a8a8a;
}

.ant-select-single .ant-select-selector {
  color: #ffffff;
}

.ant-select-dropdown {
  background-color: #2c2c2c;
  color: #ffffff;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #8a8a8a;
  
  color: #ffffff;
}

.ant-select-dropdown .ant-select-item {
  background-color: #2c2c2c;
  color: #ffffff;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
  border-color: #8a8a8a !important;
  box-shadow: #8a8a8a !important;
}


.ant-select-selector:hover {
  border-color: #8a8a8a !important;
}

/* Barra de Rolagem */

::-webkit-scrollbar-track {
  background-color: #2c2c2c;
}
::-webkit-scrollbar {
  width: 4px;
  background: #8a8a8a;
}
::-webkit-scrollbar-thumb {
  background: #8a8a8a;
}


/* Ribbon */

.ant-ribbon {
  background-color: #2c2c2c;
  color: #ffffff;
}


/* Spin */

.ant-spin .ant-spin-dot-item.ant-ribbon {
  background-color: #ffffff;
}

.ant-spin .ant-spin-dot-holder {
  color: #ffffff;
}


/* Divider */

.ant-divider-horizontal.ant-divider-with-text {
  color:#ffffff;
  border-block-start: #ffffff;
}

/* Popup */

.ant-popover .ant-popover-inner-content {
  background-color: #2c2c2c;
}

.ant-popover .ant-popover-inner {
  background-color: #2c2c2c;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title {
  color:#ffffff;
}

.ant-btn-default {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #2c2c2c;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #2c2c2c;
}


.ant-popconfirm .ant-popconfirm-message >.ant-popconfirm-message-icon .anticon {
  display: none;
}

.ant-popconfirm .ant-popconfirm-buttons button {
  border: 1px solid #ffffff;
}

/* Collapse */

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  color: #ffffff;
}

.ant-collapse {
  border: 1px solid #2c2c2c;
}

.ant-collapse .ant-collapse-content {
  background-color: #2c2c2c;
}

.slick-prev:hover,
.slick-next:hover {
  color: #ffffff; /* Cor das setas ao passar o mouse */
}

.slick-prev:before, .slick-next:before {
  color: #7e3434; 
}

.slick-next {
  font-size: 15px !important;
  right: -35px;
}

.slick-prev {
  font-size: 15px !important;
  left: -30px;
}

.slick-prev:before, .slick-next:before {
  content: '' !important;
}


.slick-dots li.slick-active button:before {
  color: #ffffff; /* Cor do dot ativo */
}

.ant-card .ant-card-extra {
  color: white
}

.ant-list * {
  color: white
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  color: white
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: white
}


@font-face {
  font-family: 'NomeDaFonte';
  src: url('http://themes.googleusercontent.com/static/fonts/montserrat/v3/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf') format('truetype');
}

.custom-title {
  font-family: 'NomeDaFonte', sans-serif; /* Adicione uma fonte de fallback */
  font-size: 20px; /* Ajuste o tamanho conforme necessário */
  color: white;
  
}


.ant-drawer .ant-drawer-body {
  background-color: #131313 ;
}

.ant-drawer .ant-drawer-title {
  background-color: #131313 ;
  color: #fff
}

.ant-drawer .ant-drawer-header { 
  background-color: #131313 ;
}

.ant-drawer .ant-drawer-footer {

  background-color: #131313 ;
}


.ant-modal .ant-modal-content { 

  background-color: #2c2c2c ;
}


.transparent-card {
  border-radius: 0 0 0px 0px;
  opacity: 0.85
}

.ant-divider {
  border-block-start: 1px solid rgb(255 255 255);
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  display: inline-block;
  padding: 9px 12px;
  background: #131313;
  opacity: 0.85;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.ant-message .ant-message-notice-wrapper {
  padding: 8px;
  text-align: center;
}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: fixed;
  top: 8px;
  width: 100%;
  pointer-events: none;
  z-index: 2010;
}

.ant-form-item .ant-form-item-explain-error {
  display: none;
}

.ant-btn-color-dangerous.ant-btn-variant-outlined, .ant-btn-color-dangerous.ant-btn-variant-dashed {
  background-color: #2c2c2c;
}

.ant-result .ant-result-title {
  color: #ffffff
}

.ant-result .ant-result-subtitle {
  color: #ffffff
}